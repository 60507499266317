<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<!-- <el-form-item label="医生姓名：">
				<el-input v-model="filters.doctorName" placeholder="医生姓名" clearable></el-input>
			</el-form-item> -->
			<el-form-item label="选择时间：">
				<el-date-picker v-model="filters.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</el-form-item>
      <!-- <el-form-item label="费用类型：">
        <el-select v-model="filters.type" placeholder="选择费用类型" clearable>
          <el-option :value="0" label="问诊"></el-option>
          <el-option :value="1" label="处方"></el-option>
        </el-select>
			</el-form-item> -->
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
      <el-table :data="dataList" border style="width: 100%;">
        <el-table-column prop="name" header-align="center" align="left" label="医生姓名"></el-table-column>
        <el-table-column prop="type" header-align="center" align="left" label="类型">
          <template slot-scope="scope">
            {{
							scope.row.type === 1 ? '问诊费' :
							scope.row.type === 2 ? '诊费' :
							scope.row.type === 3 ? '处方管理费' :
							scope.row.type === 4 ? '提现' : ''
						}}
          </template>
        </el-table-column>
        <el-table-column prop="costs" header-align="center" align="left" label="金额（元）">
          <template slot-scope="scope">
            {{scope.row.costs}}
          </template>
        </el-table-column>
				<el-table-column prop="orderId" header-align="center" align="left" label="订单号"></el-table-column>
				<el-table-column prop="createdAt" header-align="center" align="left" label="创建时间"></el-table-column>
        <!-- <el-table-column prop="operation" header-align="center" align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="detailHandle(scope.row)">查看详情</el-button>
          </template>
        </el-table-column> -->
      </el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { dateFormat } from '@/utils';
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				timeRange: ''
			},
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
			this.$root.request('cashOutDoctorDetailList', {
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1],
				id: this.$route.query.id,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.createdAt = dateFormat(new Date(r.createdAt), 'yyyy-MM-dd hh:mm:ss');
						return r;
					});
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>